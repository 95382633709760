import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ResetPassword = () => {
  const { token } = useParams();
  const newPassword = useRef(null);
  const confirmNewPassword = useRef(null);

  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [isPasswordEqual, setIsPasswordEqual] = useState(true);
  const [userid, setUserid] = useState(null);
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };
  const successToast = () => {
    toast.success(`Your Password Has Been Reset!`, {
      data: {
        title: "success",
        text: "Success",
      },
    });
  };
  const handleSubmit = () => {
    if (newPassword.current.value !== confirmNewPassword.current.value) {
      setIsPasswordEqual(false);
      return;
    }
    axios({
      method: "post",
      url: `/api/v1/new-password`,
      data: { userid: userid, newPassword: newPassword.current.value },
    })
      .then((res) => {
        if (res.status === 202) {
          setIsPasswordEqual(true);
          setIsPasswordChanged(true);
        }
      })
      .catch((error) => {
        return console.error("An error occurred:", error);
      });
  };
  useEffect(() => {
    axios({
      method: "get",
      url: `/api/v1/verify-token/${token}`,
    })
      .then((response) => {
        if (response.status === 200) {
          setUserid(response.data.userid);
          setIsTokenValid(true);
        }
      })
      .catch((error) => {
        return console.error("An error occurred:", error);
      });
  }, []);
  return (
    <div className="w-full flex justify-center items-center h-full">
      {isTokenValid ? (
        <div className="w-[90%] sm:w-[60%] lg:w-[40%] rounded-md border border-clearanceLightBlue p-[30px] flex flex-col items-center shadow-2xl">
          <h1 className="text-[25px] font-medium">Reset Password</h1>
          <p className="text-semibold w-full lg:w-[80%] text-center my-5">
            Enter a new Password to reset the password on your account. We'll
            ask for this password whenever you Log in...
          </p>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="flex flex-col gap-2 w-full">
            <label htmlFor="newPassword">New Password</label>
            <input
              ref={newPassword}
              type="password"
              className="w-full mb-2 p-2 bg-transparent border-b border-slate-300"
            />

            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              ref={confirmNewPassword}
              type="password"
              className="w-full p-2 bg-transparent border-b border-slate-300"
            />
            {!isPasswordEqual && (
              <p className="text-semibold w-full lg:w-[80%] text-center my-5 text-red-600">
                Passwords do not match
              </p>
            )}
            {isPasswordChanged && (
              <p className="text-semibold w-full lg:w-[80%] text-center my-5 text-green-500">
                Passwords changed successfully. You may login to continue.
              </p>
            )}
            <button
              className="my-4 bg-clearanceDarkBlue rounded-md py-3 text-white"
              onClick={handleSubmit}>
              Set New Password
            </button>
          </form>
          <Link to="/auth/login">
            <button className="px-4 py-1 mt-2">Return To Login</button>
          </Link>
        </div>
      ) : (
        <div className="w-[90%] sm:w-[60%] lg:w-[40%] rounded-md border border-clearanceLightBlue p-[30px] flex flex-col items-center shadow-2xl">
          {" "}
          <h1 className="text-[25px] font-medium">
            Password Reset Link Expired
          </h1>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
