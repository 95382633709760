import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPersonalInfoData,
  setPersonalInfoValues,
} from "../../../state/reducers/personalInfoSlice";
import { FaCirclePlus, FaCircleXmark } from "react-icons/fa6";
import { setImageValue } from "../../../state/reducers/imageSlice";
import {
  setDocname,
  setJobTitle,
  setFirstName,
  setLastName,
  setCity,
  setCountry,
  setEmail,
  setPhoneNumber,
  setAddressInput,
  setPostalcode,
  setDrivinglicense,
  setNationality,
  setPlaceofbirth,
  setDateofbirth,
  setClearance,
  setImage,
  setPostalcodeInput,
} from "../../../state/reducers/personalInfoInputSlice";
function PersonalInfoComponent(props) {
  const inputValues = useSelector((state) => state.personalInfoInput);
  const dispatch = useDispatch();

  const [additionalInfo, setAdditionalInfo] = useState(false);
  const showAdditionalInfo = () => {
    setAdditionalInfo((prev) => !prev);
  };

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );

  const handleChange = (e, setter) => {
    const { name, value } = e.target;
    dispatch(setter(value));
    dispatch(setPersonalInfoValues({ ...personalInfoValues, [name]: value }));
  };

  const handleImagePreview = (e) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const imageData = event.target.result;
      // Dispatch the action to set the image data in the Redux store
      dispatch(setImageValue(imageData));
    };

    if (e.target.files.length > 0) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // Use useEffect to log the updated imageValue when it changes

  return (
    <div className="w-full">
      <div className="flex flex-col items-center">
        <input
          type="text"
          id="docname"
          name="docname"
          value={inputValues.docname}
          placeholder="Untitled"
          className="px-2 py-1 w-36 outline-none text-lg"
          onChange={(e) => handleChange(e, setDocname)}
        />
        <label htmlFor="docname"></label>
      </div>
      <div className="flex items-start w-full my-4">
        <h2 className="text-xl text font-bold">Personal Information</h2>
      </div>

      <form className="mt-2 w-full" onSubmit={() => {}}>
        <div className="flex flex-col justify-between gap-x-20 align-middle w-full">
          <div className="flex items-center justify-between gap-x-6">
            <div className="w-2/4 relative my-2">
              <label htmlFor="jobTitle" className="text-sm absolute left-0">
                Desired Job Title:
              </label>
              <input
                className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                id="jobTitle"
                type="text"
                value={inputValues.jobTitle}
                name="jobTitle"
                placeholder="jobTitle"
                onChange={(e) => handleChange(e, setJobTitle)}
              />
            </div>

            <div className="w-2/4 flex flex-col gap-2 mb-3">
              <label htmlFor="image">Upload A Image</label>
              <input
                type="file"
                id="image"
                name="image"
                onChange={handleImagePreview}
                className="w-full"
              />
            </div>
          </div>
          <div className="flex items-center justify-between gap-x-6">
            <div className="w-full relative my-2">
              <label htmlFor="firstName" className="text-sm absolute left-0">
                First Name:
              </label>
              <input
                className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                id="firstName"
                type="text"
                value={inputValues.firstName}
                name="firstName"
                placeholder="First Name"
                onChange={(e) => handleChange(e, setFirstName)}
              />
            </div>

            <div className="w-full relative my-2">
              <label htmlFor="lastName" className="text-sm absolute left-0">
                Last Name:
              </label>
              <input
                className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                id="lastName"
                type="text"
                value={inputValues.lastName}
                name="lastName"
                placeholder="Last Name"
                onChange={(e) => handleChange(e, setLastName)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between gap-x-6">
            <div className="w-full relative my-2">
              <label htmlFor="email" className="text-sm absolute left-0">
                E-mail:
              </label>
              <input
                className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                id="email"
                type="email"
                value={inputValues.email}
                name="email"
                placeholder="Email Address"
                onChange={(e) => handleChange(e, setEmail)}
              />
            </div>

            <div className="w-full relative my-2">
              <label htmlFor="phoneNumber" className="text-sm absolute left-0">
                Phone Number:
              </label>
              <input
                className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                id="phoneNumber"
                type="tel"
                value={inputValues.phoneNumber}
                name="phoneNumber"
                placeholder="Phone Number"
                onChange={(e) => handleChange(e, setPhoneNumber)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between gap-x-6">
            <div className="w-full relative my-2">
              <label htmlFor="city" className="text-sm absolute left-0">
                City
              </label>
              <input
                className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                id="city"
                type="text"
                value={inputValues.city}
                name="city"
                placeholder="Enter Your City"
                onChange={(e) => handleChange(e, setCity)}
              />
            </div>

            <div className="w-full relative my-2">
              <label htmlFor="country" className="text-sm absolute left-0">
                State:
              </label>
              <input
                className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                id="country"
                type="text"
                value={inputValues.country}
                name="country"
                placeholder="Country"
                onChange={(e) => handleChange(e, setCountry)}
              />
            </div>
          </div>
          <h2
            onClick={showAdditionalInfo}
            className="text-lg mt-4 font-semibold w-full flex gap-3 items-center cursor-pointer"
          >
            Add Additional Info
            {additionalInfo ? <FaCircleXmark /> : <FaCirclePlus />}
          </h2>

          {additionalInfo ? (
            <>
              <div className="flex items-center justify-between gap-x-6">
                <div className="w-full relative my-2">
                  <label htmlFor="address" className="text-sm absolute left-0">
                    Address:
                  </label>
                  <input
                    className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                    id="address"
                    type="text"
                    name="address"
                    value={inputValues.address}
                    placeholder="Your Address"
                    onChange={(e) => handleChange(e, setAddressInput)}
                  />
                </div>

                <div className="w-full relative my-2">
                  <label
                    htmlFor="postalcode"
                    className="text-sm absolute left-0"
                  >
                    Postal Code:
                  </label>
                  <input
                    className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                    id="postalcode"
                    type="text"
                    name="postalcode"
                    value={inputValues.postalcode}
                    placeholder="Postal Code"
                    onChange={(e) => handleChange(e, setPostalcodeInput)}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between gap-x-6">
                <div className="w-full relative my-2">
                  <label
                    htmlFor="drivinglicense"
                    className="text-sm absolute left-0"
                  >
                    Driving License:
                  </label>
                  <input
                    className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                    id="drivinglicense"
                    type="text"
                    name="drivinglicense"
                    value={inputValues.drivinglicense}
                    placeholder="Driving License"
                    onChange={(e) => handleChange(e, setDrivinglicense)}
                  />
                </div>

                <div className="w-full relative my-2">
                  <label
                    htmlFor="nationality"
                    className="text-sm absolute left-0"
                  >
                    Nationality:
                  </label>
                  <input
                    className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                    id="nationality"
                    type="text"
                    name="nationality"
                    value={inputValues.nationality}
                    placeholder="Nationality"
                    onChange={(e) => handleChange(e, setNationality)}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between gap-x-6">
                <div className="w-full relative my-2">
                  <label
                    htmlFor="placeofbirth"
                    className="text-sm absolute left-0"
                  >
                    Place of Birth:
                  </label>
                  <input
                    className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                    id="placeofbirth"
                    type="text"
                    name="placeofbirth"
                    placeholder="Place of Birth"
                    value={inputValues.placeofbirth}
                    onChange={(e) => handleChange(e, setPlaceofbirth)}
                  />
                </div>

                <div className="w-full relative my-2">
                  <label
                    htmlFor="dateofbirth"
                    className="text-sm absolute left-0"
                  >
                    Date of Birth:
                  </label>
                  <input
                    className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                    id="dateofbirth"
                    type="date"
                    name="dateofbirth"
                    placeholder="Date of Birth"
                    value={inputValues.dateofbirth}
                    onChange={(e) => handleChange(e, setDateofbirth)}
                  />
                </div>
              </div>
              {/* Security Clearance Section Starts Here */}
              <div className="w-full relative my-2">
                <label htmlFor="clearance" className="text-sm absolute left-0">
                  Security Clearance
                </label>
                <input
                  className="w-full h-12 bg-cosretBlue-300 px-3 text-black text-sm mt-7 focus:outline-none border-[0.5px] border-[#323232] rounded-lg"
                  id="clearance"
                  type="text"
                  name="clearance"
                  placeholder="Security Clearance"
                  value={inputValues.clearance}
                  onChange={(e) => handleChange(e, setClearance)}
                />
              </div>

              {/* Security Clearance Section Ends Here  */}
            </>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default PersonalInfoComponent;
