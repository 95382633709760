import React from "react";
import Failed from "../../assets/images/paymentfailed.png";
import { Link } from "react-router-dom";
const PaymentFailed = () => {
  return (
    <div className="w-full h-full flex justify-center items-center bg-[#f5f3f5]">
      <div className="w-[40%] max-[1100px]:w-[70%] bg-white py-[50px] px-[30px] max-[650px]:px-[20px] flex flex-col items-center rounded-lg">
        <img
          src={Failed}
          alt="Payment Successful"
          className="w-[30%] max-[650px]:w-[50%] mb-2"
        />
        <h1 className="text-[red] text-center font-bold text-[42px] max-[650px]:text-[26px] mb-5 leading-[1.2em]">
          Error Processing Payment
        </h1>
        <h2 className="font-semibold text-center mb-5">
          Please Check your Security Code, Card Details and Connection and Try
          again!
        </h2>
        <Link to="/dashboard/payment">
          <button className="bg-[red] px-[30px] py-[10px] text-white rounded-md mt-5">
            Back
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentFailed;
