import { configureStore } from "@reduxjs/toolkit";
import educationSlice from "./state/reducers/educationSlice";
import employmentSlice from "./state/reducers/employmentSlice";
import skillSlice from "./state/reducers/skillSlice";
import personalInfoSlice from "./state/reducers/personalInfoSlice";
import websiteSlice from "./state/reducers/websiteSlice";
import editorSlice from "./state/reducers/editorSlice";
import colorSlice from "./state/reducers/colorSlice";
import templateSlice from "./state/reducers/templateSlice";
import imageSlice from "./state/reducers/imageSlice";
import resumeimageSlice from "./state/reducers/resumeimageSlice";
import loadUserSlice from "./state/reducers/loadUserSlice";
import visibilityReducer from "./state/reducers/visibilitySlice";
import personalInfoInputSlice from "./state/reducers/personalInfoInputSlice";
import textEditorSlice from "./state/reducers/textEditor";
import customSectionSlice from "./state/reducers/customsectionSlice";
import educationInputsSlice from "./state/reducers/educationInputSlice";
import employemntInputsSlice from "./state/reducers/employmentInputSlice";
import untitledInputsSlice from "./state/reducers/untitledInputSlice";
import websiteInputsSlice from "./state/reducers/websitesInputSlice";
import skillInputsSlice from "./state/reducers/skillsInputSlice";
import choosetemplateSlice from "./state/reducers/choosetemplateSlice";
const rootReducer = {
  education: educationSlice,
  employment: employmentSlice,
  skills: skillSlice,
  personal: personalInfoSlice,
  website: websiteSlice,
  editor: editorSlice,
  color: colorSlice,
  template: templateSlice,
  image: imageSlice,
  resumeImage: resumeimageSlice,
  loadUser: loadUserSlice,
  textEditor: textEditorSlice,
  visibility: visibilityReducer,
  personalInfoInput: personalInfoInputSlice,
  customSection: customSectionSlice,
  educationInputs: educationInputsSlice,
  employemntInputs: employemntInputsSlice,
  untitledInputs: untitledInputsSlice,
  websiteInputs: websiteInputsSlice,
  skillInputs: skillInputsSlice,
  chooseTemplate: choosetemplateSlice,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
