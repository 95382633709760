import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  View,
  Document,
  Text,
  Link,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import exportAsImage from "./resumecontrolbar";
import { fetchButtonVisibility } from "../state/actions/visibilityActions";
import { reduceDownload } from "../state/actions/visibilityActions";
import { Provider } from "react-redux";
import store from "../store";
import { setResumeImage3 } from "../state/reducers/resumeimageSlice";
import { generate1 } from "./resume3Word";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
const Resume3 = () => {
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const onclick = () => {
    dispatch(reduceDownload());
  };
  useEffect(() => {
    dispatch(fetchButtonVisibility());
  }, [dispatch]);
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage3(imageData));
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [3000]);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);
  useEffect(() => {
    let container = containerRef.current;
    if (!colorValue && !container) return;
    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );

  const Resume3Download = () => {
    Font.register({
      family: "Poppins-semi",
      src: require("../fonts/Poppins/Poppins-SemiBold.ttf"), // Make sure to provide the correct path to your font file.
    });

    Font.register({
      family: "Poppins-bold",
      src: require("../fonts/Poppins/Poppins-Bold.ttf"), // Make sure to provide the correct path to your font file.
    });
    Font.register({
      family: "Poppins-regular",
      src: require("../fonts/Poppins/Poppins-Regular.ttf"),
    });
    const styles = {
      container: {
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: 16,
        paddingRight: 16,
        margin: 16,
        borderLeftWidth: 4,
        borderRightWidth: 4,
        borderColor: "black",
        color: colorValue,
        fontFamily: "Poppins-regular",
      },
      header: {
        fontSize: 17,
        fontFamily: "Poppins-bold",
      },
      infoSection: {
        flexDirection: "row",
        justifyContent: "center",
        borderBottomWidth: 1,
        borderBottomColor: "black",
        marginBottom: 8,
        flexWrap: "wrap",
      },
      infoText: {
        margin: 2,
        fontSize: 11,
        fontFamily: "Poppins-regular",
      },
      semiboldtitle: {
        margin: 2,
        fontSize: 11,
        fontFamily: "Poppins-semi",
      },
      sectionTitle: {
        fontSize: 14,
        fontFamily: "Poppins-bold",
        width: "100%",
        textAlign: "center",
        marginTop: 5,
        marginBottom: 5,
      },
      customSection: {
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      },
      listItem: {
        fontSize: 11,
        marginLeft: 8,
        fontFamily: "Poppins-semi",
      },
      expertiseArea: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
      },
      linkStyleArea: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      },
      linkStyle: {
        fontSize: "11px",
        textDecoration: "underline",
        textAlign: "center",
        marginLeft: "4px",
        marginBottom: "5px",
        color: "black",
      },
      job: {
        marginBottom: 2,
        width: "100%",
      },
      edu: {
        marginBottom: 2,
        width: "100%",
      },
      flexItems: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      },
    };

    return (
      <Provider store={store}>
        <Document>
          <Page size="A4">
            <View style={styles.container}>
              <Text style={styles.header}>
                {personalInfoValues.firstName} {personalInfoValues.lastName}
              </Text>
              {personalInfoValues ? (
                <View style={styles.infoSection}>
                  {/* ... All the personal info details ... */}
                  <Text style={styles.infoText}>{personalInfoValues.city}</Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.country}
                  </Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.phoneNumber}
                  </Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.email}
                  </Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.address}
                  </Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.postalcode}
                  </Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.drivinglicense}
                  </Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.nationality}
                  </Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.placeofbirth}
                  </Text>
                  <Text style={styles.infoText}>
                    {personalInfoValues.dateofbirth}
                  </Text>
                </View>
              ) : null}

              <Text style={styles.sectionTitle}>
                {personalInfoValues.jobTitle}
              </Text>
              <Text style={styles.infoText}>{editorValue}</Text>

              {/* Areas of Expertise */}
              {skillsData[0] ? (
                <View style={styles.customSection}>
                  <Text style={styles.sectionTitle}>Areas of Expertise</Text>
                  <View style={styles.expertiseArea}>
                    {skillsData.map((item, index) => (
                      <View key={index} style={styles.expertiseArea}>
                        <Text key={index} style={styles.listItem}>
                          {item}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              ) : null}

              {/* Links */}
              {websiteData[0] ? (
                <View style={styles.customSection}>
                  <Text style={styles.sectionTitle}>Links</Text>
                  {websiteData.map((item, index) => (
                    <View key={index} style={styles.expertiseArea}>
                      <Link
                        key={index}
                        style={styles.linkStyle}
                        src={item.link}>
                        {item.label}
                      </Link>
                    </View>
                  ))}
                </View>
              ) : null}
              {personalInfoValues.clearance ? (
                <Text style={styles.infoText}>
                  Security Clearance: {personalInfoValues.clearance}
                </Text>
              ) : null}

              {/* Work History */}
              {employementData[0] ? (
                <View>
                  <Text style={styles.sectionTitle}>Work History</Text>
                  {employementData.map((item, index) => (
                    <View key={index} style={styles.job}>
                      <View style={styles.flexItems}>
                        <Text style={styles.semiboldtitle}>
                          {item.jobtitle1}
                        </Text>
                        <Text style={styles.infoText}>
                          {item.jobstart} - {item.jobend}
                        </Text>
                      </View>
                      <Text style={styles.infoText}>
                        {item.employer}, {item.jobcity}
                      </Text>
                      <Text style={styles.infoText}>{item.jobdescription}</Text>
                    </View>
                  ))}
                </View>
              ) : null}

              {/* Education */}
              {educationData[0] ? (
                <View>
                  <Text style={styles.sectionTitle}>Education</Text>
                  {educationData.map((item, index) => (
                    <View key={index} style={styles.edu}>
                      <View style={styles.flexItems}>
                        <Text style={styles.semiboldtitle}>{item.degree}</Text>
                        <Text style={styles.infoText}>
                          {item.start} - {item.end}
                        </Text>
                      </View>
                      <Text style={styles.infoText}>
                        {item.school}, {item.city1}
                      </Text>
                      <Text style={styles.infoText}>{item.edudescription}</Text>
                    </View>
                  ))}
                </View>
              ) : null}

              {/* custom Section */}
              {customSectionData[0] ? (
                <View>
                  {customSectionData.map((item, index) => (
                    <View key={index} style={styles.edu}>
                      <Text style={styles.sectionTitle}>
                        {item.sectionTitle}
                      </Text>

                      <View style={styles.flexItems}>
                        <Text style={styles.semiboldtitle}>
                          {item.activity} {item.customSectionCity}
                        </Text>

                        <Text style={styles.infoText}>
                          {item.customStartDate} - {item.customEndDate}
                        </Text>
                      </View>

                      <Text style={styles.infoText}>
                        {item.customDescription}
                      </Text>
                    </View>
                  ))}
                </View>
              ) : null}
            </View>
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    <div className="h-full bg-white min-h-[846px] w-[600px] flex flex-col items-center">
      <div
        ref={containerRef}
        className="flex flex-col  overflow-y-scroll hide-scrollbar  max-[1100px]:mt-0 h-full w-full max-[650px]:w-full  p-[20px]">
        <div className="border-l-4 border-r-4 border-black px-2 flex flex-col items-center py-5">
          <h1 className="text-2xl font-bold max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            {personalInfoValues.firstName} {personalInfoValues.lastName}{" "}
          </h1>
          <div className="flex flex-wrap gap-x-2 gap-1 justify-center w-full border-b border-black my-2 pb-2 text-[11px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            <p>{personalInfoValues.city}</p>
            <p>{personalInfoValues.country}</p>
            <p>{personalInfoValues.phoneNumber}</p>
            <p>{personalInfoValues.email}</p>
            <p>{personalInfoValues.address}</p>
            <p>{personalInfoValues.postalcode}</p>
            <p>{personalInfoValues.drivinglicense}</p>
            <p>{personalInfoValues.nationality}</p>
            <p>{personalInfoValues.placeofbirth}</p>
            <p>{personalInfoValues.dateofbirth}</p>
          </div>

          <h2 className=" font-semibold text-[16px] max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            {personalInfoValues.jobTitle}
          </h2>
          <p className="text-center text-[11px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            {editorValue}
          </p>
          {skillsData[0] ? (
            <div className="w-full my-2 text-center px-5  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
              <h2 className="mb-1 font-semibold text-[14px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                Areas of Expertise
              </h2>
              <div className="flex gap-x-5 justify-center flex-wrap max-[650px]:gap-x-5">
                {skillsData.map((item, index) => {
                  return (
                    <ul
                      key={index}
                      className="text-[11px] font-semibold list-disc max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                      <li className="ml-2">{item}</li>
                    </ul>
                  );
                })}
              </div>
            </div>
          ) : null}
          {websiteData[0] ? (
            <div className="w-full my-2 text-center px-5">
              <h2 className="mb-1 font-semibold text-[14px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                Links
              </h2>
              <div className="flex gap-x-5 justify-center flex-wrap max-[650px]:gap-x-2">
                {websiteData.map((item, index) => {
                  return (
                    <h3
                      className="underline flex pb-1 text-[11px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]"
                      onClick={() => window.open(`//${item.link}`, "_blank")}>
                      {item.label}
                    </h3>
                  );
                })}
              </div>
            </div>
          ) : null}
          {personalInfoValues.clearance ? (
            <div className="flex gap-2 text-[11px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
              <h2 className="font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                Security Clearance :
              </h2>
              <p>{personalInfoValues.clearance}</p>
            </div>
          ) : null}
          {employementData[0] ? (
            <div className="my-2 w-full flex flex-col items-center  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
              <h2 className="text-[14px] font-semibold max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                Work History
              </h2>
              {employementData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full text-[11px] max-[650px]:text-[9px] mt-2">
                    <div className="flex justify-between w-full">
                      <h3 className="font-semibold">{item.jobtitle1}</h3>
                      <h3>
                        {item.jobstart} - {item.jobend}
                      </h3>
                    </div>
                    <h3>
                      {item.employer}, {item.jobcity}
                    </h3>
                    <p>{item.jobdescription}</p>
                  </div>
                );
              })}
            </div>
          ) : null}

          {educationData[0] ? (
            <div className="my-2 w-full flex flex-col items-center  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
              <h2 className="text-[14px] font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                Education
              </h2>
              {educationData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full max-[650px]:text-[9px] mt-2 text-[11px]">
                    <div className="flex justify-between w-full">
                      <h3 className="font-semibold">{item.degree}</h3>
                      <h3>
                        {item.start} - {item.end}
                      </h3>
                    </div>
                    <h3>
                      {item.school}, {item.city1}
                    </h3>
                    <p>{item.edudescription}</p>
                  </div>
                );
              })}
            </div>
          ) : null}

          {customSectionData[0] ? (
            <div className="my-2 w-full flex flex-col items-center  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
              {customSectionData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="w-full max-[650px]:text-[9px] mt-2 text-[11px]">
                    <h2 className="text-[14px] font-semibold  max-[650px]:text-[9px] text-center max-[650px]:leading-[1.2em]">
                      {item.sectionTitle}
                    </h2>
                    <div className="flex justify-between w-full">
                      <h3>
                        {item.activity}, {item.customSectionCity}
                      </h3>
                      <h3>
                        {item.customStartDate} - {item.customEndDate}
                      </h3>
                    </div>

                    <p>{item.customDescription}</p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>

      <div className="mt-4 bg-clearanceGrey w-full items-center gap-5 flex justify-center">
        {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume3Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}>
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}>
            Download PDF
          </button>
        )}
        {isButtonVisible ? (
          <button
            className="bg-white text-[15px] px-[8px] py-[5px] rounded-md border border-[#4A90E2] text-black font-semibold "
            onClick={() => {
              generate1(
                personalInfoValues,
                educationData,
                employementData,
                skillsData,
                websiteData,
                editorValue,
                customSectionData
              );
            }}>
            Export to Word
          </button>
        ) : (
          <button
            className="bg-white text-[15px] px-[8px] py-[5px] rounded-md border border-[#4A90E2] text-black font-semibold "
            onClick={() => {
              navigate("/dashboard/payment");
            }}>
            Export to Word
          </button>
        )}
        {isButtonVisible ? (
          <ReactToPrint
            trigger={() => {
              return (
                <button
                  style={{
                    textDecoration: "none",
                    padding: "5px 8px",
                    color: "black",
                    fontWeight: 500,
                    backgroundColor: "white",
                    border: "1px solid #4A90E2",
                    borderRadius: "4px",
                    margin: "10px 0",
                  }}>
                  Print
                </button>
              );
            }}
            content={() => containerRef.current}
          />
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}>
            Print
          </button>
        )}
      </div>
    </div>
  );
};

export default Resume3;
