import React from "react";
import {
  AlignmentType,
  Document,
  Packer,
  Paragraph,
  TextRun,
  BorderStyle,
} from "docx";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";

export const generate = (
  personalInfoValues,
  educationData,
  employementData,
  skillsData,
  websiteData,
  editorValue,
  customSectionData
) => {
  const noBorders = {
    top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },

    bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    insideHorizontal: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
    insideVertical: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
  };

  const Borders = {
    top: { style: BorderStyle.SINGLE, size: 200, color: "#a68f0d" },
  };

  const TitleBorders = {
    bottom: { style: BorderStyle.SINGLE, size: 50, color: "#a68f0d" },
  };
  let sectionChildren = [];

  const createParagraph = (
    text,
    font,
    size,
    bold = false,
    alignment = AlignmentType.CENTER,
    spacingAfter = 150,
    border = {}
  ) => {
    return new Paragraph({
      alignment,
      children: [new TextRun({ text, font, size, bold })],
      spacing: { after: spacingAfter },
      border,
    });
  };

  if (personalInfoValues.firstName || personalInfoValues.lastName) {
    sectionChildren.push(
      createParagraph(
        `${personalInfoValues.firstName} ${personalInfoValues.lastName}`,
        "Arial",
        30,
        true,
        AlignmentType.CENTER,
        150,
        Borders
      )
    );
  }
  if (personalInfoValues.jobTitle) {
    sectionChildren.push(
      createParagraph(`${personalInfoValues.jobTitle}`, "Arial", 25, true)
    );
  }
  if (personalInfoValues.city || personalInfoValues.country) {
    sectionChildren.push(
      createParagraph(
        `${personalInfoValues.city}, ${personalInfoValues.country}, `,
        "Arial",
        20
      )
    );
  }

  if (personalInfoValues.email || personalInfoValues.phoneNumber) {
    sectionChildren.push(
      createParagraph(
        `${personalInfoValues.email}, ${personalInfoValues.phoneNumber}, `,
        "Arial",
        20
      )
    );
  }

  if (
    personalInfoValues.address ||
    personalInfoValues.postalcode ||
    personalInfoValues.drivinglicense ||
    personalInfoValues.nationality ||
    personalInfoValues.placeofbirth ||
    personalInfoValues.dateofbirth
  ) {
    sectionChildren.push(
      createParagraph(
        `${personalInfoValues.address}, ${personalInfoValues.postalcode}, ${personalInfoValues.drivinglicense} ${personalInfoValues.nationality} ${personalInfoValues.placeofbirth} ${personalInfoValues.dateofbirth}`,
        "Arial",
        20,
        false,
        AlignmentType.CENTER,
        150
      )
    );
  }
  if (personalInfoValues.clearance) {
    sectionChildren.push(
      createParagraph("Security Clearance", "Arial", 25, true),
      createParagraph(`${personalInfoValues.clearance}`, "Arial", 20, false)
    );
  }

  if (editorValue) {
    sectionChildren.push(
      createParagraph("Solutions Architect", "Arial", 25, true),
      createParagraph(`${editorValue}`, "Arial", 20)
    );
  }

  if (skillsData && skillsData.length > 0) {
    sectionChildren.push(
      createParagraph("Technical Expertise", "Arial", 25, true)
    );
    skillsData.forEach((skill) => {
      sectionChildren.push(createParagraph(skill, "Arial", 20));
    });
  }

  if (websiteData && websiteData.length > 0) {
    sectionChildren.push(
      createParagraph("Websites and Social Links", "Arial", 25, true)
    );
    websiteData.forEach((website) => {
      sectionChildren.push(createParagraph(website.label, "Arial", 20));
    });
  }

  // Employment Data
  if (employementData && employementData.length > 0) {
    sectionChildren.push(
      createParagraph("Professional Experience", "Arial", 25, true)
    );
    employementData.forEach((job) => {
      sectionChildren.push(
        createParagraph(
          `${job.jobtitle1}`,
          "Arial",
          20,
          true,
          AlignmentType.LEFT
        )
      );
      sectionChildren.push(
        createParagraph(
          `${job.employer}, ${job.jobcity}, ${job.jobstart} - ${job.jobend}`,
          "Arial",
          20,
          false,
          AlignmentType.LEFT
        )
      );
      sectionChildren.push(
        createParagraph(
          job.jobdescription,
          "Arial",
          20,
          false,
          AlignmentType.LEFT
        )
      );
    });
  }

  // Education Data
  if (educationData && educationData.length > 0) {
    sectionChildren.push(
      createParagraph("Educations & Certifications", "Arial", 25, true)
    );
    educationData.forEach((edu) => {
      sectionChildren.push(
        createParagraph(`${edu.degree}`, "Arial", 20, true, AlignmentType.LEFT)
      );
      sectionChildren.push(
        createParagraph(
          `${edu.school},${edu.city1} ${edu.start} - ${edu.end}`,
          "Arial",
          20,
          false,
          AlignmentType.LEFT
        )
      );
      sectionChildren.push(
        createParagraph(
          edu.edudescription,
          "Arial",
          20,
          false,
          AlignmentType.LEFT
        )
      );
    });
  }

  // Custom Section Data
  if (customSectionData && customSectionData.length > 0) {
    customSectionData.forEach((section) => {
      sectionChildren.push(
        createParagraph(section.sectionTitle, "Arial", 25, true)
      );
      sectionChildren.push(
        createParagraph(
          `${section.activity}, ${section.customSectionCity}, ${section.customStartDate} - ${section.customEndDate}`,
          "Arial",
          20,
          true,
          AlignmentType.LEFT
        )
      );
      sectionChildren.push(
        createParagraph(
          section.customDescription,
          "Arial",
          20,
          false,
          AlignmentType.LEFT
        )
      );
    });
  }
  const doc = new Document({
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 1440,
              right: 1440,
              bottom: 1440,
              left: 1440,
              header: 720,
              footer: 720,
              gutter: 0,
            },
          },
        },
        children: sectionChildren,
      },
    ],
  });

  Packer.toBlob(doc)
    .then((blob) => {
      saveAs(blob, "resume.docx");
    })
    .catch((error) => {
      console.error("Error creating document:", error);
    });
};

const WordResume = () => {
  return (
    <div className="App">
      <h1>Create Your Resume</h1>
      <button onClick={generate}>Generate Resume</button>
    </div>
  );
};

export default WordResume;
