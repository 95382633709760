import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  View,
  Document,
  Text,
  Link,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { Provider } from "react-redux";
import store from "../store";
import exportAsImage from "./resumecontrolbar";
import { setResumeImage4 } from "../state/reducers/resumeimageSlice";
import { useNavigate } from "react-router-dom";
const Resume4 = () => {
  const dispatch = useDispatch();
  const isButtonVisible = useSelector(
    (state) => state.visibility.isButtonVisible
  );
  // const exportImage = async () => {
  //   try {
  //     const imageData = await exportAsImage(containerRef.current);
  //     dispatch(setResumeImage4(imageData));
  //     console.log(imageData);
  //   } catch (error) {
  //     console.error("Error capturing image:", error);
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     exportImage();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, [3000]);

  const colorValue = useSelector((state) => state.color.colorValue);
  const containerRef = useRef(null);

  useEffect(() => {
    let container = containerRef.current;

    if (!colorValue && !container) return;

    container.style.setProperty("color", colorValue);
  }, [colorValue]);

  const personalInfoValues = useSelector(
    (state) => state.personal.personalInfoValues
  );
  const editorValue = useSelector((state) => state.textEditor.textEditorValue);
  const skillsData = useSelector((state) => state.skills.skillsData);
  const websiteData = useSelector((state) => state.website.websiteData);
  const educationData = useSelector((state) => state.education.educationData);
  const employementData = useSelector(
    (state) => state.employment.employmentData
  );
  const customSectionData = useSelector(
    (state) => state.customSection.customSectionData
  );
  const navigate = useNavigate();
  const Resume4Download = () => {
    Font.register({
      family: "Poppins-semi",
      src: require("../fonts/Poppins/Poppins-SemiBold.ttf"), // Make sure to provide the correct path to your font file.
    });

    Font.register({
      family: "Poppins-bold",
      src: require("../fonts/Poppins/Poppins-Bold.ttf"), // Make sure to provide the correct path to your font file.
    });
    Font.register({
      family: "Poppins-regular",
      src: require("../fonts/Poppins/Poppins-Regular.ttf"),
    });
    const styles = {
      container: {
        padding: 20,
        height: "100%",
        color: colorValue,
      },
      header: {
        flexDirection: "row",
        justifyContent: "space-between",
        borderBottom: "2 solid black",
        paddingBottom: 5,
      },
      section: {
        borderBottom: "2 solid black",
        paddingBottom: 5,
        marginBottom: 5,
      },
      flexRow: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
      title: {
        fontSize: 18,
        marginBottom: 2,
        fontFamily: "Poppins-semi",
      },
      subTitle: {
        fontSize: 16,
        fontFamily: "Poppins-semi",
        marginTop: 5,
        marginBottom: 2,
      },
      text: {
        fontSize: 14,
        marginBottom: 2,
        marginTop: 2,
        fontFamily: "Poppins-regular",
      },
      link: {
        textDecoration: "underline",
        color: "black",
      },
    };
    return (
      <Provider store={store}>
        <Document>
          <Page size="A4" style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.name}>
                {personalInfoValues.firstName} {personalInfoValues.lastName}
              </Text>
              <Text style={styles.subTitle}>{personalInfoValues.jobTitle}</Text>
            </View>
            <View style={styles.section}>
              {personalInfoValues.address ||
              personalInfoValues.postalcode ||
              personalInfoValues.email ? (
                <View style={styles.flexRow}>
                  <Text style={styles.text}>
                    {personalInfoValues.address},{" "}
                    {personalInfoValues.postalcode}
                  </Text>
                  <Text style={styles.text}>{personalInfoValues.email}</Text>
                </View>
              ) : null}
              {personalInfoValues.city ||
              personalInfoValues.country ||
              personalInfoValues.nationality ||
              personalInfoValues.phoneNumber ? (
                <View style={styles.flexRow}>
                  <Text style={styles.text}>
                    {personalInfoValues.city}, {personalInfoValues.country},{" "}
                    {personalInfoValues.nationality}
                  </Text>
                  <Text style={styles.text}>
                    {personalInfoValues.phoneNumber}
                  </Text>
                </View>
              ) : null}
              {personalInfoValues.drivinglicense ||
              personalInfoValues.dateofbirth ||
              personalInfoValues.placeofbirth ? (
                <View style={styles.flexRow}>
                  <Text style={styles.text}>
                    {personalInfoValues.drivinglicense}
                  </Text>
                  <Text style={styles.text}>
                    {personalInfoValues.dateofbirth},{" "}
                    {personalInfoValues.placeofbirth}
                  </Text>
                </View>
              ) : null}
            </View>

            <View style={styles.section}>
              {editorValue ? (
                <View>
                  <Text style={styles.title}>Summary of Qualifications</Text>
                  <Text style={styles.text}>{editorValue}</Text>
                </View>
              ) : null}
              {personalInfoValues.clearance ? (
                <View>
                  <Text style={styles.title}>Security Clearance</Text>
                  <Text style={styles.text}>
                    {personalInfoValues.clearance}
                  </Text>
                </View>
              ) : null}
            </View>
            {employementData[0] ? (
              <View>
                <Text style={styles.title}>Career Experience</Text>
                {employementData.map((item, index) => (
                  <View key={index} style={styles.section}>
                    <Text style={styles.subTitle}>{item.jobtitle1}</Text>
                    <View style={styles.flexRow}>
                      <Text style={styles.text}>
                        {item.employer}, {item.jobcity}
                      </Text>
                      <Text style={styles.text}>
                        {item.jobstart} - {item.jobend}
                      </Text>
                    </View>
                    <Text style={styles.text}>{item.jobdescription}</Text>
                  </View>
                ))}
              </View>
            ) : null}
            {educationData[0] ? (
              <View>
                <Text style={styles.title}>Education</Text>
                {educationData.map((item, index) => (
                  <View key={index} style={styles.section}>
                    <View style={styles.flexRow}>
                      <Text style={styles.text}>
                        {item.school}, {item.city1}
                      </Text>
                      <Text style={styles.text}>
                        {item.start} - {item.end}
                      </Text>
                    </View>
                    <Text style={styles.text}>{item.degree}</Text>
                    <Text style={styles.text}>{item.edudescription}</Text>
                  </View>
                ))}
              </View>
            ) : null}
            {skillsData[0] ? (
              <View style={styles.section}>
                <Text style={styles.title}>
                  Technical Training/Certifications
                </Text>
                {skillsData.map((item, index) => (
                  <Text key={index} style={styles.text}>
                    {item}
                  </Text>
                ))}
              </View>
            ) : null}
            {websiteData[0] ? (
              <View style={styles.section}>
                <Text style={styles.title}>Websites and Social Links</Text>
                {websiteData.map((item, index) => (
                  <Link
                    key={index}
                    style={[styles.text, styles.link]}
                    src={item.link}>
                    {item.label}
                  </Link>
                ))}
              </View>
            ) : null}
            {customSectionData[0] ? (
              <View>
                {customSectionData.map((item, index) => (
                  <View key={index} style={styles.section}>
                    <Text style={styles.title}>{item.sectionTitle}</Text>
                    <View style={styles.flexRow}>
                      <Text style={styles.text}>
                        {item.activity}, {item.customSectionCity}
                      </Text>
                      <Text style={styles.text}>
                        {item.customStartDate} - {item.customEndDate}
                      </Text>
                    </View>
                    <Text style={styles.text}>{item.customDescription}</Text>
                  </View>
                ))}
              </View>
            ) : null}
          </Page>
        </Document>
      </Provider>
    );
  };
  return (
    <div className="h-full bg-white min-h-[846px] w-[600px] flex flex-col items-center">
      <div
        ref={containerRef}
        className="flex flex-col max-[1100px]:mt-0 w-full max-[650px]:w-full h-full p-[20px]">
        <div className="flex justify-between border-b-2 border-black pb-1">
          <h1 className="text-2xl font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
            {personalInfoValues.firstName} {personalInfoValues.lastName}
          </h1>
          <h2 className="text-xl font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            {personalInfoValues.jobTitle}
          </h2>
        </div>

        <div className="flex justify-between border-b-2 border-black pb-1 text-[14px]  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:py-2">
          <div className="flex flex-col  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
            <p>
              {personalInfoValues.address} {personalInfoValues.postalcode}
            </p>
            <p>
              {personalInfoValues.city} {personalInfoValues.country}{" "}
              {personalInfoValues.nationality}{" "}
            </p>
            <p>{personalInfoValues.drivinglicense}</p>
          </div>
          <div className="flex flex-col  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            <p>{personalInfoValues.email}</p>
            <p>{personalInfoValues.phoneNumber} </p>
            <p>
              {personalInfoValues.dateofbirth} {personalInfoValues.placeofbirth}{" "}
            </p>
          </div>
        </div>
        {editorValue ? (
          <div className="border-b-2 text-[14px] border-black pb-1  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:py-2">
            <h2 className="text-[16px] font-semibold mt-1  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:mt-0">
              Summary of Qualifications
            </h2>
            <p>{editorValue}</p>
            {personalInfoValues.clearance ? (
              <>
                <h2 className="text-[16px] font-semibold mt-1  max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  Security Clearance
                </h2>
                <p>{personalInfoValues.clearance}</p>
              </>
            ) : null}
          </div>
        ) : null}
        {employementData[0] ? (
          <div className="my-2  text-[14px] max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
            <h2 className="text-[16px] font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:mb-1">
              Career Experience
            </h2>
            {employementData.map((item, index) => {
              return (
                <div
                  key={index}
                  className=" max-[650px]:mb-2 text-[14px] max-[650px]:flex max-[650px]:flex-col max-[650px]:gap-1 max-[650px]:text-[9px] border-b-2 border-black py-1">
                  <h3 className="text-[14px] font-semibold  max-[650px]:text-[9px] max-[650px]:leading-[1.2em] ">
                    {item.jobtitle1}
                  </h3>

                  <div className="flex text-[14px] max-[650px]:text-[9px] justify-between ">
                    <p>
                      {item.employer}, {item.jobcity}
                    </p>
                    <p>
                      {item.jobstart} - {item.jobend}
                    </p>
                  </div>

                  <p>{item.jobdescription}</p>
                </div>
              );
            })}
          </div>
        ) : null}
        {educationData[0] ? (
          <div className="my-2">
            <h2 className="text-[16px] font-semibold border-b-2 border-black max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:mb-1">
              Education
            </h2>
            {educationData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="border-b-2 text-[14px] border-black my-2 pb-1 max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  <div className="flex justify-between">
                    <p>
                      {item.school}, {item.city1}
                    </p>
                    <p>
                      {item.start} - {item.end}
                    </p>
                  </div>
                  <p>{item.degree}</p>
                  <p>{item.edudescription}</p>
                </div>
              );
            })}
          </div>
        ) : null}
        {skillsData[0] ? (
          <div className="my-0">
            <h2 className="text-[16px] font-semibold border-b-2 border-black max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
              Technical Training/Certifications
            </h2>
            <div className="border-b-2 text-[14px] border-black my-2 pb-1 max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
              {skillsData.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          </div>
        ) : null}
        {websiteData[0] ? (
          <div className="my-2">
            <h2 className="text-[16px] font-semibold border-b-2 border-black max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
              Websites and Social Links
            </h2>
            <div className="border-b-2 border-black text-[14px] my-2 pb-1 max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
              {websiteData.map((item, index) => {
                return (
                  <h3
                    onClick={() => window.open(`//${item.link}`, "_blank")}
                    className="underline flex pb-1">
                    {item.label}
                  </h3>
                );
              })}
            </div>
          </div>
        ) : null}

        {customSectionData ? (
          <div>
            {customSectionData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="border-b-2 text-[14px] border-black  pb-1 max-[650px]:text-[9px] max-[650px]:leading-[1.2em]">
                  <h2 className="text-[16px] font-semibold border-b-2 border-black max-[650px]:text-[9px] max-[650px]:leading-[1.2em] max-[650px]:mb-1">
                    {item.sectionTitle}
                  </h2>
                  <div className="flex justify-between">
                    <p>
                      {item.activity}, {item.customSectionCity}
                    </p>
                    <p>
                      {item.customStartDate} - {item.customEndDate}
                    </p>
                  </div>
                  <p>{item.customDescription}</p>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>

      <div className="mt-4 bg-clearanceGrey w-full items-center gap-5 flex justify-center">
        {isButtonVisible ? (
          <PDFDownloadLink
            onClick={onclick}
            document={<Resume4Download />}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}>
            Download PDF
          </PDFDownloadLink>
        ) : (
          <button
            onClick={() => navigate("/dashboard/payment")}
            style={{
              textDecoration: "none",
              padding: "5px 8px",
              color: "black",
              fontWeight: 500,
              backgroundColor: "white",
              border: "1px solid #4A90E2",
              borderRadius: "4px",
              margin: "10px 0",
            }}>
            Download PDF
          </button>
        )}
      </div>
    </div>
  );
};

export default Resume4;
