import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPassWord = () => {
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const emailid = useRef(null);
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };
  const successToast = () => {
    toast.success(`Your Message Has Been Sent`, {
      data: {
        title: "success",
        text: "Success",
      },
    });
  };
  const handleSubmit = () => {
    console.log(emailid.current.value);
    try {
      axios({
        method: "post",
        url: `/api/v1/reset-password`,
        data: { email: emailid.current.value },
      }).then((response) => {
        console.log(response);
        setIsEmailSent(true);
      });
    } catch (error) {
      return console.error("An error occurred:", error);
    }
  };

  return (
    <div className="w-full flex justify-center items-center h-full">
      <div className="w-[90%] sm:w-[60%] lg:w-[40%] rounded-md border border-clearanceLightBlue p-[30px] flex flex-col items-center shadow-2xl">
        <h1 className="text-[25px] font-medium">Forgot Password?</h1>
        <p className="text-semibold w-full lg:w-[70%] text-center my-5">
          Please provide the email address that you used when signed up for your
          account
        </p>

        <form
          onSubmit={(e) => e.preventDefault()}
          className="flex flex-col gap-1 w-full">
          <label htmlFor="email">Email</label>
          <input
            ref={emailid}
            type="email"
            className="w-full p-2 bg-transparent border-b border-slate-300"
          />
          <button
            onClick={handleSubmit}
            className="my-4 bg-clearanceDarkBlue rounded-md py-3 text-white">
            Send
          </button>
          {isEmailSent && (
            <p className="text-semibold w-full lg:w-[70%] text-center my-5">
              An email for password reset has been sent to your email.
            </p>
          )}
        </form>

        <Link to="/auth/login">
          <button className="px-4 py-1 mt-2">Return To Login</button>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassWord;
