import React from "react";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "..";
import { BiPlus } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete, MdSearch, MdSettings } from "react-icons/md";
import { GiSettingsKnobs } from "react-icons/gi";
import { CiMenuKebab } from "react-icons/ci";
import { toast, ToastContainer } from "react-toastify";
import { AiFillCheckCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";

// Images
import resumeSample from "../../../../assets/images/resume.png";
import mastercardLogo from "../../../../assets/images/mastercardLogo.svg";
import visaLogo from "../../../../assets/images/visaLogo.svg";
import americanExpressLogo from "../../../../assets/images/americanExpressLogo.svg";
import paypalLogo from "../../../../assets/images/paypalLogo.svg";
import { motion } from "framer-motion";
export default function Payment() {
  const { user, projects } = useContext(UserContext);
  const [isButtonVisibleFree, setIsButtonVisibleFree] = useState(false);
  const [isButtonVisibleYearly, setIsButtonVisibleYearly] = useState(false);
  const [isButtonVisibleMonth, setIsButtonVisibleMonth] = useState(false);

  const [isPaymentPlans, setIsPaymentPlans] = useState(true);
  const [isBillingInformations, setIsBillingInformations] = useState(false);
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const showToasRes = (res) => {
    toast(`${res}`, {
      data: {
        title: "Error",
        text: res,
      },
    });
  };

  const fetchuser = async () => {
    const token = localStorage.getItem("authToken");
    try {
      await axios({
        method: "get",
        url: `/api/v1/getUser`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          const plan = res.data.userData.plan;
          if (plan === "yearly") {
            setIsButtonVisibleYearly(true);
          } else if (plan === "monthly") {
            setIsButtonVisibleMonth(true);
          } else if (plan === "basic") {
            setIsButtonVisibleFree(true);
          }
        })
        .catch((err) => {
          showToast(err);
        });
    } catch (error) {
      showToast(error);
    }
  };
  useEffect(() => {
    fetchuser();
  }, []);

  const handleMonthlyCheckout = () => {
    axios({
      method: "post",
      url: `/api/v1/create-checkout-session-monthly`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        showToast(err);
      });
  };
  const handleYearlyCheckout = () => {
    try {
      axios({
        method: "post",
        url: `/api/v1/create-checkout-session-yearly`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((err) => {
          showToast(err);
        });
    } catch (error) {
      showToast(error);
    }
  };
  const animDiv = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        mass: 0.5,
        damping: 1,
      },
    },
  };

  const handleUpgradePlan = () => {
    axios({
      method: "post",
      url: `/api/v1/upgrade-subscription`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        showToasRes(res.data.message);
        console.log(res.data.message);
        navigate("/dashboard");
      })
      .catch((err) => {
        showToast(err);
      });
  };

  const handleDowngradePlan = () => {
    try {
      axios({
        method: "post",
        url: `/api/v1/downgrade-subscription`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          console.log(res.data);
          showToasRes(res.data);
          navigate("/dashboard");
        })
        .catch((err) => {
          showToast(err);
        });
    } catch (error) {
      showToast(error);
    }
  };
  const [modalNo, setModalNo] = useState(false);
  const showModalNo = () => {
    setModalNo((prev) => !prev);
  };
  const [modalYes, setModalYes] = useState(false);
  const showModalYes = () => {
    setModalYes((prev) => !prev);
  };
  const modalAnim = {
    hidden: {
      opacity: 0,
      y: "100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        mass: 0.7,
        damping: 0.6,
        duration: 0.4,
      },
    },
  };
  return (
    <>
      <ToastContainer />
      {/* LATEST ACTIVITY */}
      <section className="mt-12 relative text-clearanceDarkBlue">
        <div className="flex justify-between items-center border-b-[3px] border-b-clearanceDarkBlue">
          <h2
            onClick={() => {
              setIsPaymentPlans(true);
              setIsBillingInformations(false);
            }}
            className={`text-[1rem] cursor-pointer ${
              isPaymentPlans
                ? "text-clearanceDarkBlue font-bold border-b-clearanceDarkBlue border-b-[3px]"
                : "text-[#323232]"
            }`}
          >
            Payment Plans
          </h2>
        </div>

        {isPaymentPlans && (
          <motion.div
            variants={animDiv}
            initial="hidden"
            whileInView="visible"
            className="w-full flex lg:overflow-x-hidden overflow-x-scroll"
          >
            {/* COL 1 */}
            <div className="w-[200px] sm:w-[25%]">
              <div className="p-3 h-32 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <h2 className="font-semibold text-xl">Compare Plans</h2>
                <p className="text-[#323232] mt-2">
                  Choose the right plan for you
                </p>
              </div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <p className="text-[#323232] mt-2">Create optimized resumes</p>
              </div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <p className="text-[#323232] mt-2">
                  Copy and paste content from site
                </p>
              </div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <p className="text-[#323232] mt-2">Unlimited resume edits</p>
              </div>
              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <p className="text-[#323232] mt-2">
                  Save resume as .pdf, .docx
                </p>
              </div>
              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <p className="text-[#323232] mt-2">
                  Download fully formatted Resume
                </p>
              </div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <p className="text-[#323232] mt-2">
                  Unlimited Resume Downloads
                </p>
              </div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <p className="text-[#323232] mt-2">
                  Save multiple variations of resume
                </p>
              </div>
            </div>
            {/* COL 2 */}
            <div className="w-[200px] sm:w-[25%]">
              <div className="p-3 h-32 flex flex-col items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <h2 className="font-semibold text-2xl text-center">
                  Free <small className="text-xs text-[#323232]"></small>
                </h2>
                {isButtonVisibleFree ? (
                  <button
                    onClick={() => {}}
                    className="w-44 mt-2 rounded-lg h-10 cursor-default flex items-center justify-center bg-[#e8d8d7] text-clearanceDarkBlue"
                  >
                    Your Plan
                  </button>
                ) : (
                  <button
                    onClick={() => {}}
                    className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white"
                  >
                    Choose This Plan
                  </button>
                )}
              </div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px] flex items-center justify-center">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>
              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]"></div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]"></div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]"></div>

              <div className="p-3 h-24 border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]"></div>
            </div>
            {/* COL 3 */}
            <div className="w-[200px] sm:w-[25%]">
              <div className="p-3 h-32 flex flex-col items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <h2 className="font-semibold text-2xl text-center">
                  $49{" "}
                  <small className="text-xs text-[#323232]">
                    /One Time Purchase
                  </small>
                </h2>

                {isButtonVisibleYearly ? (
                  <button
                    onClick={() => {}}
                    className="w-44 mt-2 rounded-lg h-10 cursor-default flex items-center justify-center bg-[#e8d8d7] text-clearanceDarkBlue"
                  >
                    Your Plan
                  </button>
                ) : (
                  <button
                    onClick={handleYearlyCheckout}
                    className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white"
                  >
                    Choose This Plan
                  </button>
                )}
                {/* <Link
                  to={"/dashboard/payment/yearly-plan"}
                  className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white"
                >
                  Choose This Plan
                </Link> */}
              </div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 flex items-center justify-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 flex items-center justify-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]"></div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]"></div>
            </div>
            {/* COL 4 */}
            <div className="w-[200px] sm:w-[25%] ">
              <div className="p-3 h-32 flex flex-col items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <h2 className="font-semibold text-2xl text-center">
                  $39{" "}
                  <small className="text-xs text-[#323232]">
                    /month (Cancel Any Time)
                  </small>
                </h2>

                {isButtonVisibleMonth ? (
                  <button
                    onClick={() => {}}
                    className="w-44 mt-2 rounded-lg h-10 cursor-default flex items-center justify-center bg-[#e8d8d7] text-white"
                  >
                    Your plan
                  </button>
                ) : (
                  <button
                    onClick={handleMonthlyCheckout}
                    className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white"
                  >
                    Choose This Plan
                  </button>
                )}
                {/* <Link
                  to={"/dashboard/payment/monthly-plan"}
                  className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white"
                >
                  Choose This Plan
                </Link> */}
              </div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 justify-center flex items-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 flex items-center justify-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 flex items-center justify-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 flex items-center justify-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 flex items-center justify-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>

              <div className="p-3 h-24 flex items-center justify-center border-b-clearanceDarkBlue border-b-[1px] border-r-clearanceDarkBlue border-r-[1px]">
                <AiFillCheckCircle size={24} className="text-clearancePink" />
              </div>
            </div>
            {/* COL 5 */}
          </motion.div>
        )}
        <div className="flex justify-start gap-36">
          <button
            onClick={showModalYes}
            className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white p-2"
          >
            Upgrade Plan
          </button>
          <button
            onClick={() => {
              showModalNo();
            }}
            className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white text-base p-2"
          >
            Downgrade Plan
          </button>
        </div>
        {isBillingInformations && (
          <div className="mt-7">
            <h2 className="font-semibold">Please choose your payment method</h2>

            <div className="flex lg:flex-row lg:justify-between lg:items-center mt-7 flex-col justify-center items-center gap-y-5">
              <div className="h-20 w-full lg:w-80 bg-white border-[3px] border-clearanceDarkBlue shadow-lg flex items-center justify-between p-3">
                <button onClick={() => {}}>
                  <img
                    src={mastercardLogo}
                    alt="Pay With MasterCard"
                    className=""
                  />
                </button>
                <button onClick={() => {}}>
                  <img src={visaLogo} alt="Pay With Visa" className="" />
                </button>
                <button onClick={() => {}}>
                  <img
                    src={americanExpressLogo}
                    alt="Pay With American Express"
                    className=""
                  />
                </button>
              </div>
              <div className="h-20 w-full lg:w-80 bg-white border-[3px] border-clearanceDarkBlue shadow-lg flex items-center justify-center p-3">
                <button onClick={() => {}}>
                  <img src={paypalLogo} alt="Pay With PayPal" className="" />
                </button>
              </div>
            </div>

            <hr className="border-[#323232] borer-[1px] my-20 mx-auto max-w-xl" />

            <div className="lg:px-24">
              <form className="" onSubmit={() => {}}>
                <div className="">
                  <div className="flex flex-col justify-between gap-x-20 align-middle w-full">
                    <div className="flex items-center justify-between gap-x-6">
                      <div className="w-full relative my-2">
                        <label
                          htmlFor="firstName"
                          className="text-sm absolute left-0"
                        >
                          First Name:
                        </label>
                        <input
                          className="w-full h-16 bg-cosretBlue-300 px-8 text-black text-sm mt-7 focus:outline-none border-[0.5px] rounded-lg border-[#323232]"
                          id="firstName"
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                        />
                      </div>

                      <div className="w-full relative my-2">
                        <label
                          htmlFor="lastName"
                          className="text-sm absolute left-0"
                        >
                          Last Name:
                        </label>
                        <input
                          className="w-full h-16 bg-cosretBlue-300 px-8 text-black text-sm mt-7 focus:outline-none border-[0.5px] rounded-lg border-[#323232]"
                          id="lastName"
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>

                    <div className="w-full relative my-2">
                      <label
                        htmlFor="cardNumber"
                        className="text-sm absolute left-0"
                      >
                        Card Number:
                      </label>
                      <input
                        className="w-full h-16 bg-cosretBlue-300 px-8 text-black text-sm mt-7 focus:outline-none border-[0.5px] rounded-lg border-[#323232]"
                        id="cardNumber"
                        type="number"
                        name="cardNumber"
                        placeholder="XXXX XXXX XXXX XXXX"
                      />

                      {/* <img src={mastercardLogo} alt="" className="" /> */}
                      {/* <img src={visaLogo} alt="" className="" /> */}
                      {/* <img src={americanExpressLogo} alt="" className="" /> */}
                    </div>

                    <div className="flex items-center justify-between gap-x-6">
                      <div className="w-full relative my-2">
                        <label
                          htmlFor="expiryDate"
                          className="text-sm absolute left-0"
                        >
                          Expiry Date:
                        </label>
                        <input
                          className="w-full h-16 bg-cosretBlue-300 px-8 text-black text-sm mt-7 focus:outline-none border-[0.5px] rounded-lg border-[#323232]"
                          id="expiryDate"
                          type="date"
                          name="expiryDate"
                          placeholder="Expiry Date"
                        />
                      </div>

                      <div className="w-full relative my-2">
                        <label
                          htmlFor="cvv"
                          className="text-sm absolute left-0"
                        >
                          CVV:
                        </label>
                        <input
                          className="w-full h-16 bg-cosretBlue-300 px-8 text-black text-sm mt-7 focus:outline-none border-[0.5px] rounded-lg border-[#323232]"
                          id="cvv"
                          type="number"
                          name="cvv"
                          placeholder="CVV"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex justify-center lg:mt-10 mt-10">
                    <button
                      type="submit"
                      className="rounded-lg text-white h-16 w-1/2 px-8 text-sm bg-clearanceDarkBlue"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {modalNo ? (
          <motion.div
            variants={modalAnim}
            initial="hidden"
            animate="visible"
            className="w-full flex flex-col items-center justify-center top-0 absolute h-full  rounded-md bg-[black] bg-opacity-80"
          >
            <div className="px-5 py-4 bg-white h-[300px] items-center w-[50%] flex flex-col justify-center rounded-md">
              <h2 className="text-[20px] font-semibold text-center">
                Are You Sure, You Want to Downgrade Your Plan?
              </h2>
              <div className="flex gap-5 mt-5">
                <button
                  onClick={showModalNo}
                  className="bg-transparent px-4 py-2 border border-black rounded-md text-black font-semibold"
                >
                  No
                </button>
                <button
                  onClick={handleDowngradePlan}
                  className="bg-red-500 px-4 py-2 rounded-md text-white font-semibold"
                >
                  Yes
                </button>
              </div>
            </div>
          </motion.div>
        ) : null}
        {modalYes ? (
          <motion.div
            variants={modalAnim}
            initial="hidden"
            animate="visible"
            className="w-full flex flex-col items-center justify-center top-0 absolute h-full bg-[black] bg-opacity-80 rounded-md"
          >
            <div className="px-5 py-4 bg-white h-[300px] items-center w-[50%] flex flex-col justify-center rounded-md ">
              <h2 className="text-[20px] font-semibold text-center">
                You are upgrading your Plan...
              </h2>
              <div className="flex gap-5 mt-5">
                <button
                  onClick={showModalYes}
                  className="bg-transparent px-4 py-2 border border-black rounded-md text-black font-semibold"
                >
                  No
                </button>
                <button
                  onClick={handleUpgradePlan}
                  className="bg-green-500 px-4 py-2 rounded-md text-white font-semibold"
                >
                  Yes
                </button>
              </div>
            </div>
          </motion.div>
        ) : null}
      </section>
    </>
  );
}
