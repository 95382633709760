import React from "react";
import Success from "../../assets/images/paysuccess.png";
import { Link } from "react-router-dom";
const PaySuccess = () => {
  return (
    <div className="w-full h-full flex justify-center items-center bg-[#f5f3f5]">
      <div className="w-[40%] max-[1100px]:w-[70%] bg-white py-[50px] px-[30px] flex flex-col items-center rounded-lg">
        <img
          src={Success}
          alt="Payment Successful"
          className="w-[30%] max-[650px]:w-[50%]"
        />
        <h1 className="text-[#5a8f54] font-bold text-[42px] max-[650px]:text-[30px]">
          Thank You!
        </h1>
        <h2 className="font-semibold">Payment Done Successful</h2>
        <p className="text-center">Click here to return to the dashboard...</p>
        <Link to="/dashboard">
          <button className="bg-[#5a8f54] px-[30px] py-[10px] text-white rounded-md mt-5">
            Back
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PaySuccess;
