import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { MdLock, MdLockOutline, MdMailOutline, MdPerson } from "react-icons/md";
import SignupFormSchema from "./validation";
import { toast, ToastContainer } from "react-toastify";

function SignupForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(""); // For server error messages
  const [serverRes, setServerRes] = useState(""); // For server error messages

  const Router = useNavigate();
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    try {
      const response = await axios.post("/api/v1/register", {
        name: values.fullName,
        email: values.email,
        password: values.password,
      });
      if (response && response.data.message) {
        setServerRes(response.data.message);
      }
      // Navigate or set user state on successful signup, depending on your flow
      // Or wherever you want to redirect to
    } catch (error) {
      // Display the error message from the server
      if (error.response && error.response.data.message) {
        setServerError(error.response.data.message);
      } else {
        setServerError("An error occurred. Please try again later.");
      }
      showToast(error);

      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: SignupFormSchema,
    onSubmit,
  });

  return (
    <div className="signup-form-container">
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col mt-14 items-center"
      >
        {serverError && <p className="text-red-500">{serverError}</p>}
        {serverRes && <p className="text-green-500">{serverRes}</p>}
        {/* Full Name Input */}
        <div className="w-full relative border-[2px] my-4 bg-transparent border-clearanceDarkBlue flex items-center rounded-full px-5 gap-x-3">
          <label htmlFor="fullName" className="text-sm inline-block">
            <MdPerson size={20} className="text-clearanceDarkBlue" />
          </label>
          <input
            className="w-full h-14 rounded-full bg-cosretBlue- px-4 text-black text-sm focus:outline-none bg-transparent"
            id="fullName"
            type="text"
            name="fullName"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            placeholder="Full Name"
          />
          {formik.errors.fullName && (
            <p className="error-text">{formik.errors.fullName}</p>
          )}
        </div>

        {/* Email Input */}
        <div className="w-full relative border-[2px] my-4 bg-transparent border-clearanceDarkBlue flex items-center rounded-full px-5 gap-x-3">
          {" "}
          <label htmlFor="email">
            <MdMailOutline size={20} />
          </label>
          <input
            className="w-full h-14 rounded-full bg-cosretBlue- px-4 text-black text-sm focus:outline-none bg-transparent"
            id="email"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Email Address"
          />
          {formik.errors.email && (
            <p className="error-text">{formik.errors.email}</p>
          )}
        </div>

        {/* Password Input */}
        <div className="w-full relative border-[2px] my-4 bg-transparent border-clearanceDarkBlue flex items-center rounded-full px-5 gap-x-3">
          {" "}
          <label htmlFor="password">
            <MdLockOutline size={20} />
          </label>
          <input
            className="w-full h-14 rounded-full bg-cosretBlue- px-4 text-black text-sm focus:outline-none bg-transparent"
            id="password"
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="Password"
          />
          {formik.errors.password && (
            <p className="error-text">{formik.errors.password}</p>
          )}
        </div>

        {/* Confirm Password Input */}
        <div className="w-full relative border-[2px] my-4 bg-transparent border-clearanceDarkBlue flex items-center rounded-full px-5 gap-x-3">
          {" "}
          <label htmlFor="confirmpassword">
            <MdLockOutline size={20} />
          </label>
          <input
            className="w-full h-14 rounded-full bg-cosretBlue- px-4 text-black text-sm focus:outline-none bg-transparent"
            id="confirmpassword"
            type="password"
            name="confirmpassword"
            value={formik.values.confirmpassword}
            onChange={formik.handleChange}
            placeholder="Confirm Password"
          />
          {formik.errors.confirmpassword && (
            <p className="error-text">{formik.errors.confirmpassword}</p>
          )}
        </div>

        {/* Submit Button */}
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Signing Up..." : "Sign Up"}
        </button>

        <p className="mt-8">
          Already have an account?{" "}
          <Link
            to="/auth/login"
            className="text-clearanceDarkBlue font-semibold"
          >
            Log in
          </Link>
        </p>
      </form>
    </div>
  );
}

export default SignupForm;
