import React from "react";
import { useContext } from "react";
import { FaRegBell } from "react-icons/fa";
import { UserContext } from "../../Dashboard";
import { Link, useNavigate } from "react-router-dom";
import { PiWalletFill } from "react-icons/pi";
import { RiNotification2Fill } from "react-icons/ri";
import axios from "axios";
// Images
import profileImage from "../../../../assets/images/profileImage.png";
import { useEffect } from "react";
import { useState } from "react";
export default function DahboardTopBar(props) {
  const [user, setUser] = useState("user");
  const navigate = useNavigate();
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem("authToken");

    axios({
      method: "get",
      url: `/api/v1/getUser`,
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
      console.log(response);
      setUser(response.data.userData.name);

      if (response.data.userData.plan === "basic") {
        console.log("basic");
        setIsButtonVisible(false);
      }
    });
  }, []);

  return (
    <div>
      <div className="pb-3 flex justify-between	 bg-accountableDarkGreen pl-10 lg:pl-[22%] py-4 pr-10">
        {isButtonVisible ? (
          <h1></h1>
        ) : (
          <button
            class="bg-transparent hover:bg-[#3a415c] text-[#3a415c] font-semibold hover:text-white py-2 px-4 border border-[#3a415c] hover:border-transparent rounded"
            onClick={() => {
              navigate("/dashboard/payment");
            }}>
            Upgrade
          </button>
        )}
        <div className="flex items-center gap-x-10">
          <p className="text-xs" onClick={() => {}}>
            Hi! <strong>{user}</strong>
          </p>
        </div>
      </div>
    </div>
  );
}
