import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { UserContext } from "..";
import { BiPlus } from "react-icons/bi";
import { Link } from "react-router-dom";
import { MdDelete, MdSearch, MdSettings } from "react-icons/md";
import { GiSettingsKnobs } from "react-icons/gi";
import { CiMenuKebab } from "react-icons/ci";
import axios from "axios";
// Images
import resumeSample from "../../../../assets/images/resume.png";
import { useSelector } from "react-redux";
import loadUserSlice from "../../../../state/reducers/loadUserSlice";
import { motion } from "framer-motion";
import ChooseTemplate from "../../../../pages/resume templates";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function DashboardHome({ showTemplates }) {
  const { user, projects } = useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState();
  const [templatesIds, setTemplateIds] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [sheleton, setSheleton] = useState(false);
  const token = localStorage.getItem("authToken");

  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const deleteTemp = async (templateid) => {
    try {
      axios({
        method: "delete",
        url: `/api/v1/delete-template/${templateid}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then((res) => {
        fetchTemplates();
      });
    } catch (error) {
      showToast(error);
    }
  };
  const fetchTemplates = async () => {
    try {
      await axios({
        method: "get",
        url: `/api/v1/templates`,
        headers: {
          Authorization: "Bearer " + token,
        },
      }).then((response) => {
        setTemplateIds(response.data.templates);
        setSheleton(false);
        console.log("response");
      });
    } catch (error) {
      showToast(error);
    }
  };
  useEffect(() => {
    setSheleton(true);
    fetchTemplates();
  }, []);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const animDiv = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        mass: 0.5,
        damping: 1,
      },
    },
  };

  const chooseTemplate = useSelector(
    (state) => state.chooseTemplate.chooseTemplate
  );

  const [modal, setModal] = useState(false);
  const showModal = () => {
    setModal((prev) => !prev);
  };
  const modalAnim = {
    hidden: {
      opacity: 0,
      y: "100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        mass: 0.7,
        damping: 0.6,
        duration: 0.4,
      },
    },
  };
  return (
    <>
      <ToastContainer />
      {/* LATEST ACTIVITY */}
      <section className=" relative text-clearanceDarkBlue">
        <div className="w-full h-full">
          {chooseTemplate ? (
            <ChooseTemplate />
          ) : (
            <>
              <h2 className="text-xl font-bold">My Resumes</h2>
              <p className="my-2 font-semibold">
                Click Create Resume To Begin!
              </p>

              <div className="flex gap-x-5 w-full mt-7">
                {/* RIGHT PANE */}
                <motion.div
                  variants={animDiv}
                  initial="hidden"
                  whileInView="visible"
                  className="bg-white shadow-xl rounded-xl w-full min-h-[500px] px-5 py-2">
                  <div className="flex justify-between items-center"></div>
                  {sheleton ? (
                    <div className="w-full flex">
                      <div
                        role="status"
                        className="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-[10px] dark:border-gray-700 w-[200px] mr-[20px]">
                        <div className="flex items-center justify-center mb-4 bg-gray-300 rounded dark:bg-gray-700 h-[270px]"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-[180px] mb-4"></div>
                      </div>
                      <div
                        role="status"
                        className="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-[10px] dark:border-gray-700 w-[200px]">
                        <div className="flex items-center justify-center mb-4 bg-gray-300 rounded dark:bg-gray-700 h-[270px]"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-[180px] mb-4"></div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-y-5 gap-x-3 justify-center lg:justify-normal mt-5">
                      {templatesIds.map((item, index) => (
                        <div
                          key={index}
                          className="h-[2da50px] w-[200px] aspect-[1/1.41] bg-white aspect-w-1 aspect-h-1.41  rounded-xl ">
                          <div
                            className="relative"
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}>
                            <Link
                              Key={index}
                              to={`/dashboard/create-resume/${item.templateid}`}>
                              <img
                                src={item.photo}
                                alt="Resume Sample Name"
                                className="w-[200px] h-[235px] rounded-xl border-[1.5px] border-clearanceDarkBlue 	  object-cover object-top"
                              />
                            </Link>

                            {hoveredIndex === index ? (
                              <div className="bg-black rounded-xl top-0 opacity-[0.7] absolute h-full gap-3 w-full flex justify-center items-center">
                                <Link
                                  to={`/dashboard/create-resume/${item.templateid}`}>
                                  <button className="px-4 py-2 bg-transparent z-[5] font-semibold text-white border-2 border-white">
                                    Edit
                                  </button>
                                </Link>
                                <MdDelete
                                  size={23}
                                  onClick={() => {
                                    setDeleteId(item.templateid);
                                    showModal();
                                  }}
                                  className="text-red-500 absolute top-3 right-4 cursor-pointer"
                                />
                              </div>
                            ) : null}
                          </div>

                          <div className="flex items-center justify-center px-3 h-[70px] border-[1.5px] relative rounded-br-xl rounded-bl-xl -top-2 border-t-transparent border-clearanceDarkBlue">
                            <p className="font-semibold">{item.templatename}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </motion.div>
              </div>
            </>
          )}
        </div>
        {modal ? (
          <motion.div
            variants={modalAnim}
            initial="hidden"
            animate="visible"
            className="w-full flex flex-col h-[80vh] items-center justify-center top-0 absolute bg-[black] bg-opacity-80 rounded-md">
            <div className="px-5 py-4 bg-white items-center flex flex-col justify-center rounded-md w-[50vw] h-[40vh]">
              <h2 className="text-[20px] text-center font-semibold">
                Do You Want to Delete the Resume?
              </h2>
              <div className="flex gap-5 mt-5">
                <button
                  onClick={showModal}
                  className="bg-transparent px-4 py-2 border border-black rounded-md text-black font-semibold">
                  No
                </button>
                <button
                  onClick={() => {
                    deleteTemp(deleteId);
                    showModal();
                  }}
                  className="bg-green-500 px-4 py-2 rounded-md text-white font-semibold">
                  Yes
                </button>
              </div>
            </div>
          </motion.div>
        ) : null}
      </section>
    </>
  );
}
